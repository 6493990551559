var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-modal",
        {
          ref: "master-invoice-modal",
          staticClass: "modal-dialog",
          attrs: {
            id: "master-invoice-modal",
            "hide-header": "",
            "ok-only": ""
          }
        },
        [
          _c("b-card", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("Master-Invoice", {
                    attrs: { propafes: this.afes, propinvoice: this.invoice }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("InvoiceHeader", {
            attrs: {
              invoice: _vm.invoice,
              manager: 1,
              highestAfe: _vm.highestAfe,
              serviceData: _vm.serviceData,
              afes: _vm.afes,
              currency: _vm.currency,
              tax: _vm.tax,
              company_name: _vm.company_name
            }
          }),
          _c("div", { staticClass: "mt-2 invoice-status" }, [
            _vm.invoice.accountant_paid === 0
              ? _c("div", [
                  _vm.invoice.inv_passed === 9
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By WSS (Submit Again)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 8
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-pm" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By Project Manager")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 7
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 6
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-warning" }, [
                          _c("i", { staticClass: "fa fa-question" }),
                          _vm._v(" Pending By Management")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 5
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(
                            " Rejected By Project Manager (Pending Vendor)"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 0
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-warning" }, [
                          _c("i", { staticClass: "fa fa-question" }),
                          _vm._v(" Pending By WSS")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 2
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-blue" }, [
                          _c("i", { staticClass: "fa fa-save" }),
                          _vm._v(" Draft")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 11
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 13
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By "),
                            _vm.authority <= 1
                              ? _c("span", [_vm._v("Higher ")])
                              : _vm._e(),
                            _vm._v("Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 14
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Management (Pending Vendor)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 21
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 23
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By "),
                            _vm.authority <= 2
                              ? _c("span", [_vm._v("Higher ")])
                              : _vm._e(),
                            _vm._v("Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 24
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Management (Pending Vendor)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 31
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 33
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By "),
                            _vm.authority <= 3
                              ? _c("span", [_vm._v("Higher ")])
                              : _vm._e(),
                            _vm._v("Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 34
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Management (Pending Vendor)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 41
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 43
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By "),
                            _vm.authority <= 4
                              ? _c("span", [_vm._v("Higher ")])
                              : _vm._e(),
                            _vm._v("Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 44
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Management (Pending Vendor)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 51
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Ready For Payment")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 54
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Management (Pending Vendor)")
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.invoice.accountant_paid === 1
              ? _c("div", [
                  _c("button", { staticClass: "btn-sm btn-success" }, [
                    _c("i", { staticClass: "fa fa-check" }),
                    _vm._v(" Paid")
                  ])
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            [
              !_vm.fromexternal
                ? _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.master-invoice-modal",
                          modifiers: { "master-invoice-modal": true }
                        }
                      ],
                      staticClass: "pull-right pl-3 btn-sm font-weight-bold",
                      attrs: { variant: "primary" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "mt-2 d-none d-sm-inline mr-1" },
                        [_vm._v("View Master Invoice ")]
                      ),
                      _c("i", { staticClass: "fas fa-search" })
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3 clearfix mb-4" },
            [
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [
                  _c("VendorDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      manager: _vm.manager,
                      vid: _vm.invoice.vid
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [
                  _c("CompanyDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      company_name: _vm.company_name
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6", lg: "4" } },
                [
                  _c("InvoiceDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      highestAfe: _vm.highestAfe,
                      vwref: _vm.vwref
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("h3", { staticClass: "d-inline-block" }, [_vm._v("Services")]),
          _vm.invoice.accountant_paid === 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger float-right",
                  attrs: { type: "button", name: "button" },
                  on: {
                    click: function($event) {
                      return _vm.removeAll()
                    }
                  }
                },
                [
                  _c("strong", [
                    _vm._v("Remove All AFES "),
                    _c("i", { staticClass: "fa fa-times" })
                  ])
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "table-responsive-sm" }, [
            _c(
              "table",
              { staticClass: "table wss-invoice-table wss-striped" },
              [
                _c("thead", [
                  _c(
                    "tr",
                    { staticClass: "service-rows service-table-headers" },
                    [
                      _c(
                        "th",
                        {
                          staticClass: "center service-name",
                          on: {
                            click: function($event) {
                              return _vm.sortServices("name")
                            }
                          }
                        },
                        [_vm._v("Service")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "center service-name",
                          on: {
                            click: function($event) {
                              return _vm.sortServices("code")
                            }
                          }
                        },
                        [_vm._v("Code")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "datepickers right",
                          on: {
                            click: function($event) {
                              return _vm.sortServices("date")
                            }
                          }
                        },
                        [_vm._v("Start Date - End Date")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "uom-select",
                          on: {
                            click: function($event) {
                              return _vm.sortServices("uom")
                            }
                          }
                        },
                        [
                          _vm._v("UOM "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "fa fa-question-circle mr-1",
                            attrs: { title: "Unit Of Measurement" }
                          })
                        ]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "price center",
                          on: {
                            click: function($event) {
                              return _vm.sortServices("price")
                            }
                          }
                        },
                        [_vm._v("Price/Unit")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "price center",
                          on: {
                            click: function($event) {
                              return _vm.sortServices("price")
                            }
                          }
                        },
                        [_vm._v("Discounted Price/Unit")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "price center",
                          on: {
                            click: function($event) {
                              return _vm.sortServices("quantity")
                            }
                          }
                        },
                        [_vm._v("Quantity")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "price center",
                          on: {
                            click: function($event) {
                              return _vm.sortServices("discount")
                            }
                          }
                        },
                        [_vm._v("Discount")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "right",
                          on: {
                            click: function($event) {
                              return _vm.sortServices("total")
                            }
                          }
                        },
                        [_vm._v("Total")]
                      ),
                      _c("th")
                    ]
                  )
                ]),
                _vm._l(_vm.unassigned, function(service) {
                  return _c("tbody", [
                    _c(
                      "tr",
                      {
                        staticClass:
                          "service-rows mt-2 service-bg unassigned rt-wss-create-afe"
                      },
                      [
                        _c(
                          "td",
                          { staticClass: "center price v-align-middle" },
                          [
                            _c("h4", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v(
                                "Service: " +
                                  _vm._s(service.name) +
                                  " " +
                                  _vm._s(service.vsid)
                              )
                            ]),
                            _c(
                              "h5",
                              { staticClass: "mb-0 d-none d-xl-block" },
                              [_vm._v(_vm._s(service.name))]
                            )
                          ]
                        ),
                        _c(
                          "td",
                          { staticClass: "center price v-align-middle" },
                          [
                            _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v("Code: " + _vm._s(service.type))
                            ]),
                            _c("span", { staticClass: "d-none d-xl-block" }, [
                              _vm._v(_vm._s(service.type))
                            ])
                          ]
                        ),
                        _c(
                          "td",
                          { staticClass: "center price v-align-middle" },
                          [
                            _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v("Start - End Date")
                            ]),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("moment")(
                                    service.start_date,
                                    "MM/DD/YYYY"
                                  )
                                ) +
                                " - " +
                                _vm._s(
                                  _vm._f("moment")(
                                    service.end_date,
                                    "MM/DD/YYYY"
                                  )
                                ) +
                                " "
                            ),
                            _c("br"),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "10px" } },
                              [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      parseInt(
                                        _vm
                                          .$moment(service.end_date)
                                          .diff(
                                            _vm.$moment(service.start_date),
                                            "days"
                                          )
                                      ) + 1
                                    ) +
                                    " Days)"
                                )
                              ]
                            )
                          ]
                        ),
                        _c("td", { staticClass: "left price v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Unit Of Measurement: " + _vm._s(service.uom)
                            )
                          ]),
                          _c("span", { staticClass: "d-none d-xl-block" }, [
                            _vm._v(_vm._s(service.uom))
                          ])
                        ]),
                        _c("td", { staticClass: "price left v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Price: $" + _vm._s(_vm.formatPrice(service.pu))
                            )
                          ]),
                          _c("span", { staticClass: "d-none d-xl-block" }, [
                            _vm._v("$" + _vm._s(_vm.formatPrice(service.pu)))
                          ])
                        ]),
                        _c("td", { staticClass: "price left v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Discounted Price/Unit:\n                " +
                                _vm._s(
                                  service.discount
                                    ? "$" +
                                        _vm._discount_price_per_unit(
                                          service.discount,
                                          service.pu
                                        )
                                    : service.name
                                    ? "$" + _vm.formatPrice(service.pu)
                                    : null
                                ) +
                                "\n              "
                            )
                          ]),
                          _c("span", { staticClass: "d-none d-xl-block" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  service.discount
                                    ? "$" +
                                        _vm._discount_price_per_unit(
                                          service.discount,
                                          service.pu
                                        )
                                    : service.name
                                    ? "$" + _vm.formatPrice(service.pu)
                                    : null
                                ) +
                                "\n              "
                            )
                          ])
                        ]),
                        _c("td", { staticClass: "price left v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Quantity: " +
                                _vm._s(parseFloat(service.quantity))
                            )
                          ]),
                          _c(
                            "span",
                            { staticClass: "d-none d-xl-inline-block" },
                            [_vm._v(_vm._s(parseFloat(service.quantity)))]
                          ),
                          /day|Day/.test(service.uom) &&
                          _vm
                            .$moment(service.end_date)
                            .diff(_vm.$moment(service.start_date), "days") +
                            1 !=
                            service.quantity
                            ? _c("span", { staticClass: "ml-1" }, [
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass: "fa fa-exclamation-triangle",
                                  attrs: {
                                    title:
                                      "Quantity does not equal sum between start and end dates (" +
                                      (parseInt(
                                        _vm
                                          .$moment(service.end_date)
                                          .diff(
                                            _vm.$moment(service.start_date),
                                            "days"
                                          )
                                      ) +
                                        1) +
                                      ")!"
                                  }
                                })
                              ])
                            : _vm._e()
                        ]),
                        _c("td", { staticClass: "price left v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Discount: " +
                                _vm._s(_vm.formatPrice(service.discount)) +
                                "%"
                            )
                          ]),
                          _c("span", { staticClass: "d-none d-xl-block" }, [
                            _vm._v(
                              _vm._s(_vm.formatPrice(service.discount)) + "%"
                            )
                          ])
                        ]),
                        _c("td", { staticClass: "right v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Total: $" +
                                _vm._s(
                                  _vm.formatPrice(
                                    service.quantity * service.pu -
                                      (service.discount / 100) *
                                        service.quantity *
                                        service.pu
                                  )
                                )
                            )
                          ]),
                          _c("span", { staticClass: "d-none d-xl-block" }, [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatPrice(
                                    service.quantity * service.pu -
                                      (service.discount / 100) *
                                        service.quantity *
                                        service.pu
                                  )
                                )
                            )
                          ])
                        ]),
                        _c(
                          "td",
                          [
                            _vm.invoice.accountant_paid === 0
                              ? _c(
                                  "b-dropdown",
                                  {
                                    staticClass: "m-md-2 wss-action-dropdown",
                                    attrs: {
                                      variant: "primary",
                                      size: "sm",
                                      right: "",
                                      disabled: _vm.disableEdits == 1,
                                      text: "Select Action"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.addNewUnassignedAfeLine(
                                              service,
                                              service
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v("AFE "),
                                        _c("i", {
                                          staticClass: "ml-1 fa fa-plus-circle"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "tr",
                      {
                        staticClass: "rt-wss-create-afe",
                        class: {
                          createAfe: service != _vm.createdUnassignedServiceLine
                        }
                      },
                      [
                        _c(
                          "td",
                          {
                            staticClass: "p-0 show",
                            attrs: { colspan: "100%" }
                          },
                          [
                            _c("div", { staticClass: "table-responsive-sm" }, [
                              _c(
                                "table",
                                { staticClass: "table no-bg afenum mb-0" },
                                [
                                  _c("thead", { staticClass: "rt-border" }, [
                                    _c("tr", { staticClass: "afe-rows" }, [
                                      _c("th", { staticClass: "actions fit" }),
                                      _c("th", { staticClass: "center" }, [
                                        _c("strong", [_vm._v("AFE / PO")])
                                      ]),
                                      _c("th", { staticClass: "center" }, [
                                        _c("strong", [
                                          _vm._v("% "),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            staticClass:
                                              "fa fa-question-circle",
                                            attrs: { title: "AFE Percentage" }
                                          })
                                        ])
                                      ]),
                                      _c("th", { staticClass: "center" }, [
                                        _c("strong", [
                                          _vm._v("Cost Code Chain")
                                        ])
                                      ]),
                                      _c("th", { staticClass: "center" })
                                    ])
                                  ]),
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            staticClass:
                                              "mt-1 fit btn btn-sm btn-danger",
                                            attrs: {
                                              type: "button",
                                              title: "AFE / PO Cancel"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.cancelAfe()
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times-circle"
                                            })
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "d-xs-block d-xl-none"
                                            },
                                            [_vm._v("AFE / PO")]
                                          ),
                                          service ==
                                          _vm.createdUnassignedServiceLine
                                            ? _c("v-select", {
                                                attrs: {
                                                  label: "afenum",
                                                  options: _vm.createAfeData,
                                                  value: _vm.afenum
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.getcc1Create()
                                                  }
                                                },
                                                model: {
                                                  value: _vm.createAfenum,
                                                  callback: function($$v) {
                                                    _vm.createAfenum = $$v
                                                  },
                                                  expression: "createAfenum"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("Percentage")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.percentage,
                                              expression: "percentage"
                                            }
                                          ],
                                          staticClass: "rt-percent",
                                          attrs: {
                                            type: "number",
                                            step: "any"
                                          },
                                          domProps: { value: _vm.percentage },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.percentage =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c("v-select", {
                                            staticClass: "mt-2",
                                            attrs: {
                                              options: _vm.cc1CreateData,
                                              filterable: true,
                                              label: "" + _vm.search_cc
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "list-footer",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "li",
                                                        {
                                                          ref: "load",
                                                          refInFor: true,
                                                          staticClass: "loader"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Loading more options...\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                },
                                                {
                                                  key: "option",
                                                  fn: function(option) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            option.costcode1
                                                          )
                                                        )
                                                      ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          "-" +
                                                            _vm._s(
                                                              option.costcode2
                                                            )
                                                        )
                                                      ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          "-" +
                                                            _vm._s(
                                                              option.costcode3
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "selected-option",
                                                  fn: function(ref) {
                                                    var costcode1 =
                                                      ref.costcode1
                                                    var costcode2 =
                                                      ref.costcode2
                                                    var costcode3 =
                                                      ref.costcode3
                                                    var option = ref.option
                                                    var deselect = ref.deselect
                                                    var multiple = ref.multiple
                                                    var disabled = ref.disabled
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(costcode1)
                                                        )
                                                      ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          "-" +
                                                            _vm._s(costcode2)
                                                        )
                                                      ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          "-" +
                                                            _vm._s(costcode3)
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.CC_data,
                                              callback: function($$v) {
                                                _vm.CC_data = $$v
                                              },
                                              expression: "CC_data"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td", { staticClass: "actions" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm mt-1 btn-success mr-2",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addAfeUnassignedLine(
                                                  service
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v("Add "),
                                            _c("i", {
                                              staticClass: "fa fa-plus"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm mt-1 btn-success",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addAfeToAllInvoiceServices(
                                                  service
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v("Add To All "),
                                            _c("i", {
                                              staticClass: "fa fa-plus"
                                            })
                                          ]
                                        )
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                }),
                _vm._l(_vm.merged, function(afe, index) {
                  return _c(
                    "tbody",
                    [
                      _vm._l(afe, function(service, serviceIndex) {
                        return _c(
                          "tr",
                          {
                            staticClass:
                              "service-rows mt-2 service-bg rt-wss-create-afe"
                          },
                          [
                            _c(
                              "td",
                              { staticClass: "center price v-align-middle" },
                              [
                                _c(
                                  "h4",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [
                                    _vm._v(
                                      "Service: " +
                                        _vm._s(service.name) +
                                        " " +
                                        _vm._s(service.vsid)
                                    )
                                  ]
                                ),
                                _c(
                                  "h5",
                                  { staticClass: "mb-0 d-none d-xl-block" },
                                  [_vm._v(_vm._s(service.name))]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "center price v-align-middle" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Code: " + _vm._s(service.type))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-xl-block" },
                                  [_vm._v(_vm._s(service.type))]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "center price v-align-middle" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Start - End Date")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        service.start_date,
                                        "MM/DD/YYYY"
                                      )
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        service.end_date,
                                        "MM/DD/YYYY"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "10px" } },
                                  [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          parseInt(
                                            _vm
                                              .$moment(service.end_date)
                                              .diff(
                                                _vm.$moment(service.start_date),
                                                "days"
                                              )
                                          ) + 1
                                        ) +
                                        " Days)"
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "left price v-align-middle" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [
                                    _vm._v(
                                      "Unit Of Measurement: " +
                                        _vm._s(service.uom)
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-xl-block" },
                                  [_vm._v(_vm._s(service.uom))]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "price left v-align-middle" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [
                                    _vm._v(
                                      "Price: $" +
                                        _vm._s(_vm.formatPrice(service.pu))
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-xl-block" },
                                  [
                                    _vm._v(
                                      "$" + _vm._s(_vm.formatPrice(service.pu))
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "price left v-align-middle" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [
                                    _vm._v(
                                      "Discounted Price/Unit:\n                " +
                                        _vm._s(
                                          service.discount
                                            ? "$" +
                                                _vm._discount_price_per_unit(
                                                  service.discount,
                                                  service.pu
                                                )
                                            : service.name
                                            ? "$" + _vm.formatPrice(service.pu)
                                            : null
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-xl-block" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          service.discount
                                            ? "$" +
                                                _vm._discount_price_per_unit(
                                                  service.discount,
                                                  service.pu
                                                )
                                            : service.name
                                            ? "$" + _vm.formatPrice(service.pu)
                                            : null
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "price left v-align-middle" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [
                                    _vm._v(
                                      "Quantity: " +
                                        _vm._s(parseFloat(service.quantity))
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-xl-inline-block" },
                                  [_vm._v(_vm._s(parseFloat(service.quantity)))]
                                ),
                                /day|Day/.test(service.uom) &&
                                _vm
                                  .$moment(service.end_date)
                                  .diff(
                                    _vm.$moment(service.start_date),
                                    "days"
                                  ) +
                                  1 !=
                                  service.quantity
                                  ? _c("span", { staticClass: "ml-1" }, [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true }
                                          }
                                        ],
                                        staticClass:
                                          "fa fa-exclamation-triangle",
                                        attrs: {
                                          title:
                                            "Quantity does not equal sum between start and end dates (" +
                                            (parseInt(
                                              _vm
                                                .$moment(service.end_date)
                                                .diff(
                                                  _vm.$moment(
                                                    service.start_date
                                                  ),
                                                  "days"
                                                )
                                            ) +
                                              1) +
                                            ")!"
                                        }
                                      })
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "price left v-align-middle" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [
                                    _vm._v(
                                      "Discount: " +
                                        _vm._s(
                                          _vm.formatPrice(service.discount)
                                        ) +
                                        "%"
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-xl-block" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPrice(service.discount)
                                      ) + "%"
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c("td", { staticClass: "right v-align-middle" }, [
                              _c(
                                "h6",
                                { staticClass: "d-xs-block d-xl-none" },
                                [
                                  _vm._v(
                                    "Total: $" +
                                      _vm._s(
                                        _vm.formatPrice(
                                          service.quantity * service.pu -
                                            (service.discount / 100) *
                                              service.quantity *
                                              service.pu
                                        )
                                      )
                                  )
                                ]
                              ),
                              _c("span", { staticClass: "d-none d-xl-block" }, [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatPrice(
                                        service.quantity * service.pu -
                                          (service.discount / 100) *
                                            service.quantity *
                                            service.pu
                                      )
                                    )
                                )
                              ])
                            ]),
                            _c(
                              "td",
                              [
                                _vm.invoice.accountant_paid === 0 &&
                                _vm.showAfeEnterButton
                                  ? _c(
                                      "b-dropdown",
                                      {
                                        staticClass:
                                          "m-md-2 wss-action-dropdown",
                                        attrs: {
                                          variant: "primary",
                                          size: "sm",
                                          right: "",
                                          disabled: _vm.disableEdits == 1,
                                          text: "Select Action"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.addNewAfeLine(
                                                  afe,
                                                  service
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v("AFE "),
                                            _c("i", {
                                              staticClass:
                                                "ml-1 fa fa-plus-circle"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.removeAllAfe(service)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v("Remove All Assigned AFES "),
                                            _c("i", {
                                              staticClass: "ml-1 fa fa-times"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      _c("tr", [
                        _c(
                          "td",
                          { staticClass: "p-0", attrs: { colspan: "100%" } },
                          [
                            _c("div", { staticClass: "table-responsive-sm" }, [
                              _c(
                                "table",
                                { staticClass: "table no-bg afenum mb-0" },
                                [
                                  _c("thead", { staticClass: "rt-border" }, [
                                    _c("tr", { staticClass: "afe-rows" }, [
                                      _c("th", { staticClass: "actions fit" }),
                                      _c("th", { staticClass: "center" }, [
                                        _c("strong", [_vm._v("AFE / PO")])
                                      ]),
                                      _c("th", { staticClass: "center" }, [
                                        _c("strong", [
                                          _vm._v(" AFE Percentage % "),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            staticClass:
                                              "fa fa-question-circle",
                                            attrs: { title: "AFE Percentage" }
                                          })
                                        ])
                                      ]),
                                      _c("th", { staticClass: "center" }, [
                                        _c("strong", [
                                          _vm._v(" Cost Code Chain ")
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(afe[0].afes, function(
                                        line,
                                        aIndex
                                      ) {
                                        return _c(
                                          "tr",
                                          {
                                            staticClass:
                                              "rt-wss-create-afe rt-mobile-indent",
                                            class: {
                                              editingAfe:
                                                line == _vm.editedAfeLine
                                            }
                                          },
                                          [
                                            _c("td", {
                                              staticClass:
                                                "d-xs-none d-lg-block actions fit border-0"
                                            }),
                                            _c("td", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "view",
                                                  on: {
                                                    click: function($event) {
                                                      ;(_vm.editAfe = 1),
                                                        _vm.editAfeLine(
                                                          line,
                                                          index,
                                                          aIndex,
                                                          _vm.afes,
                                                          afe
                                                        )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "d-block d-xl-none"
                                                    },
                                                    [_vm._v("AFE")]
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "fit btn btn-danger float-right d-inline-block d-xl-none ml-2",
                                                      attrs: {
                                                        disabled:
                                                          _vm.disableEdits == 1,
                                                        type: "button"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.removeAllAfe(
                                                            afe[0].afes
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-minus-circle"
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-primary d-inline-block d-xl-none float-right",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          ;(_vm.editAfe = 1),
                                                            _vm.editAfeLine(
                                                              line,
                                                              index,
                                                              aIndex,
                                                              _vm.afes,
                                                              afe
                                                            )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-edit"
                                                      })
                                                    ]
                                                  ),
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-chevron-circle-right"
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(line.afenum) +
                                                      "\n                                "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "edit",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.editAfe = 0
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "d-xs-block d-xl-none"
                                                    },
                                                    [_vm._v("AFE / PO")]
                                                  ),
                                                  line == _vm.editedAfeLine
                                                    ? _c("v-select", {
                                                        attrs: {
                                                          label: "afenum",
                                                          options:
                                                            _vm.serviceChainData,
                                                          value: _vm.afenum
                                                        },
                                                        model: {
                                                          value: line.afenum,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              line,
                                                              "afenum",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "line.afenum"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]),
                                            _c("td", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "view",
                                                  on: {
                                                    click: function($event) {
                                                      ;(_vm.editAfe = 1),
                                                        _vm.editAfeLine(
                                                          line,
                                                          index,
                                                          aIndex,
                                                          _vm.afes,
                                                          afe
                                                        )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "d-xs-block d-xl-none"
                                                    },
                                                    [_vm._v("Percentage")]
                                                  ),
                                                  _vm._v(
                                                    "\n                                  " +
                                                      _vm._s(line.percentage) +
                                                      "\n                                "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "edit" },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "d-xs-block d-xl-none"
                                                    },
                                                    [_vm._v("Percentage")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.percentage,
                                                        expression: "percentage"
                                                      }
                                                    ],
                                                    staticClass: "rt-percent",
                                                    attrs: {
                                                      type: "number",
                                                      step: "any"
                                                    },
                                                    domProps: {
                                                      value: _vm.percentage
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.percentage =
                                                          $event.target.value
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            ]),
                                            _c("td", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "view",
                                                  on: {
                                                    click: function($event) {
                                                      ;(_vm.editAfe = 1),
                                                        _vm.editAfeLine(
                                                          line,
                                                          index,
                                                          aIndex,
                                                          _vm.afes,
                                                          afe
                                                        )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "d-xs-block d-xl-none"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Cost Code #1 / Major"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                                  " +
                                                      _vm._s(line.ccone_code) +
                                                      " -- " +
                                                      _vm._s(line.cctwo_code) +
                                                      " -- " +
                                                      _vm._s(
                                                        line.ccthree_code
                                                      ) +
                                                      "\n                                "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "edit",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.editAfe = 0
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "d-xs-block d-xl-none"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Cost Code #1 / Major"
                                                      )
                                                    ]
                                                  ),
                                                  line == _vm.editedAfeLine
                                                    ? _c("v-select", {
                                                        staticClass: "mt-2",
                                                        attrs: {
                                                          options: _vm.cc1Data,
                                                          filterable: true,
                                                          label:
                                                            "" + _vm.search_cc
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "list-footer",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "li",
                                                                    {
                                                                      ref:
                                                                        "load",
                                                                      refInFor: true,
                                                                      staticClass:
                                                                        "loader"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                    Loading more options...\n                                  "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key: "option",
                                                              fn: function(
                                                                option
                                                              ) {
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        option.costcode1
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "-" +
                                                                        _vm._s(
                                                                          option.costcode2
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "-" +
                                                                        _vm._s(
                                                                          option.costcode3
                                                                        )
                                                                    )
                                                                  ])
                                                                ]
                                                              }
                                                            },
                                                            {
                                                              key:
                                                                "selected-option",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var costcode1 =
                                                                  ref.costcode1
                                                                var costcode2 =
                                                                  ref.costcode2
                                                                var costcode3 =
                                                                  ref.costcode3
                                                                var option =
                                                                  ref.option
                                                                var deselect =
                                                                  ref.deselect
                                                                var multiple =
                                                                  ref.multiple
                                                                var disabled =
                                                                  ref.disabled
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        costcode1
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "-" +
                                                                        _vm._s(
                                                                          costcode2
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "-" +
                                                                        _vm._s(
                                                                          costcode3
                                                                        )
                                                                    )
                                                                  ])
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value: _vm.CC_data,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.CC_data = $$v
                                                          },
                                                          expression: "CC_data"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "actions" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "edit" },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-primary",
                                                        attrs: {
                                                          type: "button"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.editAddAfeToInvoice(
                                                              line,
                                                              afe,
                                                              index,
                                                              aIndex,
                                                              _vm.afes
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-edit"
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      _c(
                                        "tr",
                                        {
                                          staticClass: "rt-wss-create-afe",
                                          class: {
                                            createAfe:
                                              afe != _vm.createdServiceLine
                                          }
                                        },
                                        [
                                          _c("td", { staticClass: "show" }, [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover",
                                                    modifiers: { hover: true }
                                                  }
                                                ],
                                                staticClass:
                                                  "fit btn btn-sm btn-danger",
                                                attrs: {
                                                  type: "button",
                                                  title: "AFE Cancel"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.cancelAfe()
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-times-circle"
                                                })
                                              ]
                                            )
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              { staticClass: "show" },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [_vm._v("AFE / PO")]
                                                ),
                                                afe == _vm.createdServiceLine
                                                  ? _c("v-select", {
                                                      attrs: {
                                                        label: "afenum",
                                                        options:
                                                          _vm.createAfeData,
                                                        value: _vm.afenum
                                                      },
                                                      model: {
                                                        value: _vm.createAfenum,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.createAfenum = $$v
                                                        },
                                                        expression:
                                                          "createAfenum"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]),
                                          _c("td", [
                                            _c("div", { staticClass: "show" }, [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "d-xs-block d-xl-none"
                                                },
                                                [_vm._v("Percentage")]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.percentage,
                                                    expression: "percentage"
                                                  }
                                                ],
                                                staticClass: "rt-percent",
                                                attrs: {
                                                  type: "number",
                                                  step: "any"
                                                },
                                                domProps: {
                                                  value: _vm.percentage
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.percentage =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ])
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              { staticClass: "show" },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-xs-block d-xl-none"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Cost Code #1 / Major"
                                                    )
                                                  ]
                                                ),
                                                _c("v-select", {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    options: _vm.cc1CreateData,
                                                    filterable: true,
                                                    label: "" + _vm.search_cc
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "list-footer",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "li",
                                                              {
                                                                ref: "load",
                                                                refInFor: true,
                                                                staticClass:
                                                                  "loader"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              Loading more options...\n                            "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "option",
                                                        fn: function(option) {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  option.costcode1
                                                                )
                                                              )
                                                            ]),
                                                            _c("span", [
                                                              _vm._v(
                                                                "-" +
                                                                  _vm._s(
                                                                    option.costcode2
                                                                  )
                                                              )
                                                            ]),
                                                            _c("span", [
                                                              _vm._v(
                                                                "-" +
                                                                  _vm._s(
                                                                    option.costcode3
                                                                  )
                                                              )
                                                            ])
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "selected-option",
                                                        fn: function(ref) {
                                                          var costcode1 =
                                                            ref.costcode1
                                                          var costcode2 =
                                                            ref.costcode2
                                                          var costcode3 =
                                                            ref.costcode3
                                                          var option =
                                                            ref.option
                                                          var deselect =
                                                            ref.deselect
                                                          var multiple =
                                                            ref.multiple
                                                          var disabled =
                                                            ref.disabled
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  costcode1
                                                                )
                                                              )
                                                            ]),
                                                            _c("span", [
                                                              _vm._v(
                                                                "-" +
                                                                  _vm._s(
                                                                    costcode2
                                                                  )
                                                              )
                                                            ]),
                                                            _c("span", [
                                                              _vm._v(
                                                                "-" +
                                                                  _vm._s(
                                                                    costcode3
                                                                  )
                                                              )
                                                            ])
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: _vm.CC_data,
                                                    callback: function($$v) {
                                                      _vm.CC_data = $$v
                                                    },
                                                    expression: "CC_data"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]),
                                          _c("td", { staticClass: "actions" }, [
                                            _c("div", { staticClass: "show" }, [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-success mr-2",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addAfeLine(
                                                        afe,
                                                        _vm.line
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v("Add "),
                                                  _c("i", {
                                                    staticClass: "fa fa-plus"
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-success",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addAfeToAllInvoiceServices(
                                                        index,
                                                        afe
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v("Add To All "),
                                                  _c("i", {
                                                    staticClass: "fa fa-plus"
                                                  })
                                                ]
                                              )
                                            ])
                                          ])
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    ],
                    2
                  )
                })
              ],
              2
            )
          ]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-4 col-12 ml-auto", attrs: { lg: "12" } },
                [
                  _c("InvoiceTotals", {
                    attrs: {
                      invoice: _vm.invoice,
                      serviceData: _vm.serviceData,
                      currency: _vm.currency
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mt-4 col-12 ml-auto", attrs: { lg: "12" } },
                [
                  _vm.set === 1 || _vm.set === 2
                    ? _c("div", [
                        _c("h5", [
                          _vm._v("Comments "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "ml-2 fa fa fa-question-circle",
                            attrs: {
                              title:
                                "Comments will be seen by all user levels and be attached on invoice"
                            }
                          })
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.comments,
                              expression: "comments"
                            }
                          ],
                          staticClass: "w-100 rounded border mt-2 mb-4",
                          attrs: { name: "comments", id: "", rows: "3" },
                          domProps: { value: _vm.comments },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.comments = $event.target.value
                            }
                          }
                        }),
                        _vm.role !== "Accountant"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger mr-4 mb-4",
                                    on: {
                                      click: function($event) {
                                        return _vm.submitInvoice(2)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v("Reject "),
                                    _c("i", { staticClass: "fa fa-times" })
                                  ]
                                ),
                                _c(
                                  "b-dropdown",
                                  {
                                    staticClass: "mr-4 mb-4",
                                    attrs: {
                                      variant: "primary",
                                      text: "Send Back To"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.kickBack(8)
                                          }
                                        }
                                      },
                                      [_vm._v("Project Manager")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.kickBack(0)
                                          }
                                        }
                                      },
                                      [_vm._v("Well Site Supervisor")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success mb-4",
                                    attrs: { type: "submit" },
                                    on: {
                                      click: function($event) {
                                        return _vm.submitInvoice(1)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(" Approve")
                                  ]
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _vm.invoice.qbyte_status.code <= 1
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-danger mr-2 mt-3 mb-3",
                                        on: {
                                          click: function($event) {
                                            return _vm.rejectInvoice(2)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-times-circle"
                                        }),
                                        _vm._v(" Reject")
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.invoice.qbyte_status.code <= 1
                                  ? _c(
                                      "b-dropdown",
                                      {
                                        staticClass: "mt-3 mb-3",
                                        attrs: {
                                          variant: "primary",
                                          text: "Send Back To"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.AccountantkickBack(0)
                                              }
                                            }
                                          },
                                          [_vm._v("Well Site Supervisor")]
                                        ),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.AccountantkickBack(8)
                                              }
                                            }
                                          },
                                          [_vm._v("Project Manager")]
                                        ),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.AccountantkickBack(6)
                                              }
                                            }
                                          },
                                          [_vm._v("Manager")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.invoiceError,
                                expression: "invoiceError"
                              }
                            ],
                            staticClass: "d-block"
                          },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.invoiceError,
                                  expression: "invoiceError"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.invoiceError,
                                    expression: "invoiceError"
                                  }
                                ],
                                staticClass: "help"
                              },
                              [
                                _vm._v(
                                  "Invoice Error. Please fix invoice or contact admin."
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [_c("InvoiceHistory", { attrs: { history: _vm.history } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }